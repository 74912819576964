import React from 'react';
import Slide1 from 'src/slides/desktop/hu/chapter_2/sub_3/slide1';
import WrapperMobile from 'src/slides/mobile/hu/chapter_2/sub_3/wrapperMobile';
import { isMobile } from 'react-device-detect';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';
import SEO from '../../../components/_shared/seo';

// desktop

const allSlides = [
  <Slide1 />,
];
const exceptions = [];
const Desktop = () => (
  <>

    <SEO title="A bolsevik világforradalom | A varsói csata" lang="hu" description="A világuralomra törő bolsevik tervek története." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);
const Mobile = () => (
  <>
    <SEO title="A bolsevik világforradalom | A varsói csata" lang="hu" description="A világuralomra törő bolsevik tervek története." />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
